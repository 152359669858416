
import { defineComponent } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import BackBtn from '@/components/BackBtn.vue';
import LocomotiveScroll from 'locomotive-scroll';
import json2022 from '@/assets/data/works2022.json';
import json2021 from '@/assets/data/works2021.json';
import json2020 from '@/assets/data/works2020.json';
import json2019 from '@/assets/data/works2019.json';
import json2018 from '@/assets/data/works2018.json';
import json2017 from '@/assets/data/works2017.json';
import json2016 from '@/assets/data/works2016.json';
import json2015 from '@/assets/data/works2015.json';
import json2014 from '@/assets/data/works2014.json';
import json2013 from '@/assets/data/works2013.json';
import json2012 from '@/assets/data/works2012.json';
import json2011 from '@/assets/data/works2011.json';
import json2010 from '@/assets/data/works2010.json';

class Work {
  id = '';
  year = '';
  date = '';
  title = '';
  client = '';
  detail = '';
  pickup = false;
  display = true;
}

export default defineComponent({
  name: 'Works',
  components: {
    PageHeader, BackBtn
  },
  data() {
    return {
      selectedImg: {
        src: '',
        year: '',
        date: '',
        title: '',
        now: 1,
        total: 0
      },
      selectedYear: this.$route.params.year,
      selectedYearWorks: [] as Array<Work>
    }
  },
  computed: {
    works2022(){ return json2022.filter(function(w:any){ return w.display === true }); },
    works2021(){ return json2021.filter(function(w:any){ return w.display === true }); },
    works2020(){ return json2020.filter(function(w:any){ return w.display === true }); },
    works2019(){ return json2019.filter(function(w:any){ return w.display === true }); },
    works2018(){ return json2018.filter(function(w:any){ return w.display === true }); },
    works2017(){ return json2017.filter(function(w:any){ return w.display === true }); },
    works2016(){ return json2016.filter(function(w:any){ return w.display === true }); },
    works2015(){ return json2015.filter(function(w:any){ return w.display === true }); },
    works2014(){ return json2014.filter(function(w:any){ return w.display === true }); },
    works2013(){ return json2013.filter(function(w:any){ return w.display === true }); },
    works2012(){ return json2012.filter(function(w:any){ return w.display === true }); },
    works2011(){ return json2011.filter(function(w:any){ return w.display === true }); },
    works2010(){ return json2010.filter(function(w:any){ return w.display === true }); },
  },
  mounted() {
    this.setSelectedYearWorks()
    this.$nextTick(function () { //DOM更新まで待機
      this.setLocomotive();
    });
  },
  updated() {
    // this.$nextTick(function () { //DOM更新まで待機
      this.setSelectedYearWorks()
      document.querySelector<HTMLElement>(".progress span")!.style.width = '0'
    // });
  },
  methods: {
    getImgUrl(year:string, id:string){
      return '/img/illust/'+year + '/' +id.split('-')[1] +'/main.png'
    },
    getNextYear(){
      return this.selectedYear=='2010' ? '' : Number(this.selectedYear)-1;
    },
    mouseOverAction(index:number) {
      this.selectedImg.now = index+1;
    },
    setSelectedYearWorks(){
      this.selectedYear = this.$route.params.year;
      switch(this.selectedYear){
        case '2022': this.selectedYearWorks = this.works2022; break;
        case '2021': this.selectedYearWorks = this.works2021; break;
        case '2020': this.selectedYearWorks = this.works2020; break;
        case '2019': this.selectedYearWorks = this.works2019; break;
        case '2018': this.selectedYearWorks = this.works2018; break;
        case '2017': this.selectedYearWorks = this.works2017; break;
        case '2016': this.selectedYearWorks = this.works2016; break;
        case '2015': this.selectedYearWorks = this.works2015; break;
        case '2014': this.selectedYearWorks = this.works2014; break;
        case '2013': this.selectedYearWorks = this.works2013; break;
        case '2012': this.selectedYearWorks = this.works2012; break;
        case '2011': this.selectedYearWorks = this.works2011; break;
        case '2010': this.selectedYearWorks = this.works2010; break;
      }
    },
    setLocomotive(){
      const body = document.querySelector<HTMLElement>("body")
      const container = document.querySelector<HTMLElement>(".works__body")
      const elem = document.querySelector<HTMLElement>('.works__list');
      let bar = document.querySelector<HTMLElement>(".progress span")

      //サムネイルのスクロール
      let locomotive = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        smartphone: {
          smooth: false,
          direction: 'vertical'
        },
        // tablet: {
        //   smooth: true
        // },
        direction: 'horizontal',
        multiplier: 1,
        horizontalGesture: true
      });

      //DOMが変更されたら（年が切り替わったら）、locomotiveScrollを初期化する
      var observer = new MutationObserver(function(){
          locomotive.destroy();
          locomotive.init();
          locomotive.on('scroll', function(obj:any){
            bar!.style.width = (Number(obj.scroll.x)/ (Number(container!.clientWidth)-Number(body!.clientWidth)-150))* 100 + '%';
          });
        }
      );
      const config = { 
        // attributes: true, 
        childList: true, 
        // characterData: true 
      };
      observer.observe(elem!, config);

      //スクロール距離に応じて進捗バーのwidthを変更
      locomotive.on('scroll', function(obj:any){
        bar!.style.width = (Number(obj.scroll.x)/ (Number(container!.clientWidth)-Number(body!.clientWidth)-150))* 100 + '%';
      });

      // let scroll = {cache: 0, current: 0};
      // const allImgs =  document.querySelectorAll<HTMLElement>('.works__bg');

      // locomotive.on('scroll', (obj:any) => {
      //   scroll.current = obj.scroll.x;
      //   const distance = scroll.current - scroll.cache;
      //   scroll.cache = scroll.current;
      //   const skewVal = map(distance, -50, 50, -15, 15);
      //   for(let i=0; i<allImgs.length; i++){
      //     allImgs[i].style.transform = 'skewX('+clamp(-skewVal, -5, 5)+'deg)';
      //   }
      // });
      // // Map number x from range [a, b] to [c, d]
      // function map(x:number, a:number, b:number, c:number, d:number){
      //   return (x - a) * (d - c) / (b - a) + c;
      // }
      // function clamp(num:number, min:number, max:number) {
      //   return num <= min ? min : num >= max ? max : num;
      // }

      locomotive.update();
    }
  },
  beforeRouteEnter (to, from, next) {
    document.querySelector('html')?.classList.add('works-page');
    next();
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('html')?.classList.remove('works-page');
    next();
  }
});
